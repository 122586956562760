<template>
	<div>
		<CCard class="p-4">
			<CRow>

				<CCol lg="6">
					<h5>Furnishing ({{ dataList.total }})</h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<CButton v-if="config.getPermission('furnishing').create" size="sm" style="margin-right: 10px"
						color="success" href="#/main/create_furnishing" target="_blank" class="float-lg-right"><i
							class="fa fa-plus"></i>Add Furnishing
					</CButton>


				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>

			<CRow>
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CDataTable :loading="isLoading" :items="dataList.data" :fields="fields" hover outlined>
							<template #status="{ item }">
								<td style="padding: 5px; width: 100px; text-transform:uppercase;">
									<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}</CBadge>
								</td>
							</template>
							<template #action="{ item }">
								<td style="padding: 5px; width: 100px">
									<CDropdown toggler-text="Select" size="sm" color="info">
										<CDropdownItem v-if="config.getPermission('furnishing').view"
											@click="viewModal(item)"><i class="fa fa-eye">View</i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('furnishing').update"
											@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i>
										</CDropdownItem>
										<CDropdownItem v-if="config.getPermission('furnishing_transfer').update"
											@click="showTransferModal(item)"><i class="fa fa-download"> Transfer Furnishing
											</i></CDropdownItem>
										<CDropdownItem @click="showTransferHistoryModal(item)"><i class="fa fa-clock-o">
												Transfer History </i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('furnishing').delete"
											@click="deleteItem(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>
									</CDropdown>
								</td>
							</template>
						</CDataTable>
						<CPagination :active-page.sync="currentPage" :pages="Math.ceil(dataList.total / 20)"
							:activePage="currentPage" @update:activePage="updatePage" />
					</div>
				</CCol>
			</CRow>
		</CCard>
		<!-- TRANSFER DATA -->
		<CModal :title="title" :show.sync="formModal" @close="closeModal()" color="info" size="lg">

			<form @submit.prevent="submit">

				<CRow>
					<CCol lg="8">
						<small class="mr-2">Current Location</small>
						<CInput disabled :value="transferParam.current_location_label" type="text" class="mb-2" />
					</CCol>
					<CCol lg="4">
						<small class="mr-2">Asset No.</small>
						<CInput disabled :value="transferParam.asset_no" type="text" class="mb-2" />
					</CCol>
				</CRow>
				<hr>

				<CRow>
					<CCol lg="12">
						<small class="mr-2">Transfer Date <span class="text-danger">*</span></small>
						<Datepicker input-class="form-control" v-model="transferParam.transfer_date" class="mb-2"
							required />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<small class="mr-2">Origin<span class="text-danger">*</span></small>
						<v-select @input="originChanged" v-model="transferParam.origin"
							:options="customerList.data" label="customer_name" class="mb-2">
							<template #search="{ attributes, events }">
								<input class="vs__search" :required="!transferParam.origin" v-bind="attributes"
									v-on="events" />
							</template>
						</v-select>
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<small class="mr-2">Transfer To<span class="text-danger">*</span></small>
						<v-select @input="transferToChanged" v-model="transferParam.transfer_to"
							:options="customerList.data" label="customer_name" class="mb-2">
							<template #search="{ attributes, events }">
								<input class="vs__search" :required="!transferParam.transfer_to" v-bind="attributes"
									v-on="events" />
							</template>
						</v-select>
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<small class="mr-2">Depot</small>
						<CInput disabled
							:value="(transferParam.transfer_to ? transferParam.transfer_to.setting_name : '')"
							type="text" class="mb-2" />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<small class="mr-2">MTS No. <span class="text-danger">*</span> </small>
						<CInput v-model="transferParam.mts_no" type="text" class="mb-2" required />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<small class="mr-2">Remarks <span class="text-danger">*</span></small>
						<textarea class="form-control mb-2" required v-model="transferParam.remarks"></textarea>
					</CCol>
				</CRow>


				<div slot="footer" class="w-100 mt-3">
					<!-- to avoid enabling button via inspect element -->
					<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" v-if="is_valid"
						color="info" class="ml-1 mr-1 float-right">
						Save
					</CButton>
					<CButton size="sm" v-else type="button" style="border-radius: .2rem; color: white;" disabled
						color="info" class="ml-1 mr-1 float-right">
						Save
					</CButton>
					<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger"
						class="ml-1 mr-1 float-right" @click="formModal = false">
						Cancel
					</CButton>

				</div>
			</form>
			<div slot="footer" class="w-100"></div>
		</CModal>

		<CModal title="Transfer Furnishing History" :show.sync="historyModal" color="info" size="xl">
			<!-- <h5>ASSET NO:
			{{ history_display.asset_no }}
			 </h5> -->

			<CRow>
					<CCol lg="4">
						<small class="mr-2">Asset No.</small>
						<CInput disabled :value="transferParam.asset_no" type="text" class="mb-2" />
					</CCol>
					<CCol lg="8">
						<small class="mr-2">Current Location</small>
						<CInput disabled :value="transferParam.current_location_label" type="text" class="mb-2" />
					</CCol>
					
				</CRow>
				<hr>
			<CRow>
				<CCol>
					<CDataTable :items="history" :fields="[
							{
								key: 'transfer_date',
								label: 'Transfer Date'
							},
							{
								key: 'origin',
								label: 'Origin'
							},
							{
								key: 'transfer_to',
								label: 'Transfer To'
							},
							{
								key: 'mts_no',
								label: 'MTS No.'
							},
							{
								key: 'remarks',
								label: 'Remarks'
							},
							{
								key: 'updated_by',
								label: 'Updated By'
							}
						]" hover outlined small fixed>
					</CDataTable>

				</CCol>
			</CRow>
			<div slot="footer" class="w-100">
				<!-- <CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="closeTransfer">
					Close
				</CButton>   -->
			</div>
		</CModal>
	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../../style.css';

export default {
	mounted() {
		// this.getDepot();
		this.getData();
		this.getCustomer();
	},
	computed: {
		is_valid() {
			return this.transferParam.transfer_to && this.transferParam.transfer_date
		}
	},
	data() {
		return {
			isLoading: false,
			config,
			transferParam: {
				asset_no: '',
				current_location_id: '',
				current_location_label: '',
				transfer_date: null,
				transfer_to: {},
				mts_no: '',
				remarks: '',
				id: null,
				old_value: '',
				origin:''
			},
			dataParams: {
				current_location_id: "",
				asset_no: "",
				depot_id: "",
				vehicle_type_id: "",
				customer_id: "",
				origin_id: "",
				destination_id: "",
				rate_type: "",
				client_rate: "",
				subcon_rate: ""
			},
			// approveModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			depotName: "",
			fields: [
				{
					key: 'asset_no',
					label: 'Asset No.'
				},
				{
					key: 'description',
					label: 'Description'
				},
				{
					key: 'model',
					label: 'Model'
				},
				{
					key: 'type',
					label: 'Type'
				},
				{
					key: 'acquisition_date',
					label: 'Acquisition Date'
				},
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				
				{
					key: 'current_location_id_label',
					label: 'Current Location',
				},
				{
					key: 'depot_name',
					label: 'Depot'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action'
				}
			],
			currentPage: 1,


			editMode: false,
			selectedRow: {},
			showCollapse: false,

			filter: {
				asset_no: "",
				description: "",
				type: "",
				model: "",
				acquisition_date_from: "",
				acquisition_date_to: "",
				current_location_id: "",
				status: "",
			},
			vendorList: {
				data: []
			},
			customerList: {
				data: []
			},
			depotList: {
				data: []
			},
			title: 'Transfer Furnishing',
			historyModal: false,
			history: [],
			history_display: {
				asset_no: ''

			}
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect },
	methods: {
		getBadge(status) {
			return status === 'operational' ? 'success'
				: status === 'breakdown' ? 'dark'
				: status === 'for scrap' ? 'warning'
				: status === 'standby' ? 'secondary'
				: status === 'for erection' ? 'primary'
				: status === 'for inspection' ? 'info'
				: status === 'dismantle' ? 'light'
				: status === 'pull-out' ? 'warning'
				: status === 'for rehab' ? 'success'
				: status === 'reserve' ? 'danger' : 'primary'
		},
		submit() {
			let date = moment(this.transferParam.transfer_date);
			if (!date.isValid()) {
				Swal.fire({
					icon: 'error',
					title: '',
					text: 'Please provide a valid date.',
					showCancelButton: false,
					confirmButtonText: `Ok`,
				})
				return;
			}

			this.$showLoading(true)
			axios.post(config.api_path + '/furnishing-transfer', {
				id: this.transferParam.id,
				late_transfer_date: moment(this.transferParam.transfer_date).unix(),
				late_transfer_date_history: moment(this.transferParam.transfer_date).format('MM/DD/YYYY') + ' ' + moment().format('h:mm A'),
				mts_no: this.transferParam.mts_no,
				remarks: this.transferParam.remarks,
				current_location_id: this.transferParam.transfer_to?.id ?? null,
				origin_id: this.transferParam.origin?.id ?? null,
				depot_id: this.transferParam.transfer_to.depot_id ?? null,
				old_value: this.transferParam.old_value,
				// origin_id: this.transferParam.old_value?.origin_id ?? null,

			}).then(res => {
				this.$showLoading(false)
				this.formModal = false;
				this.getData()
			})
				.catch(err => {
					this.$showLoading(false)
				})
		},
		transferToChanged(item) {
			
			this.transferParam.transfer_to = item;
		},
		originChanged(item) {
			
			this.transferParam.origin = item;
		},
		showTransferHistoryModal(item) {
			let id = item.id;
			this.history = [];
			this.history_display.asset_no = item.asset_no ?? '';
			this.$showLoading(true)
			axios.get(config.api_path + '/furnishing/transfer/history', {
				params: { id }
			}).then(res => {
				const { history, status } = res.data;
				this.transferParam.asset_no = item?.asset_no ?? '';
				this.transferParam.current_location_label = item?.current_location_id_label ?? '';
				this.$showLoading(false);
				this.historyModal = true;
				this.history = history.map(item => {
					item.transfer_date = item.transfer_date ? moment(item.transfer_date).format('MM/DD/YYYY') : '';
					item.updated_by = item.user ? `${item.user.lastname}, ${item.user.firstname}` : '';
					item.transfer_to = item?.current_location?.customer_name ?? '';
					item.origin = item.origin_id?.customer_name ?? '';
					return item;
				});
			})
				.catch(err => {
					this.$showLoading(false)
					this.historyModal = false;
				})
		},
		closeTransfer() {
			this.historyModal = false;
		},
		showTransferModal(item) {
			this.formModal = true;
			this.transferParam.id = item.id;
			this.transferParam.asset_no = item?.asset_no ?? '';
			this.transferParam.mts_no = item?.mts_no ?? '';
			this.transferParam.remarks = item?.remarks ?? '';
			this.transferParam.current_location_id = item?.current_location_id ?? '';
			this.transferParam.current_location_label = item?.current_location_id_label ?? '';
			this.transferParam.transfer_date = item?.late_transfer_date;
			this.transferParam.old_value = {
				id: item.id,
				asset_no: item.asset_no,
				mts_no: item.mts_no,
				current_location_id: item.current_location_id,
				remarks: item.remarks,
				origin_id: item.origin_id
			};
			this.transferParam.transfer_to = this.customerList.data.find(d => d.id == item.current_location_id) ?? {};
			this.transferParam.origin = this.customerList.data.find(d => d.id == item.origin_id) ?? {};
		},
		closeModal() {
			console.log('oyet')
		},
		reloadData() {
			this.getData();
		},
		updatePage(data) {
			this.currentPage = data;
			this.getData();
		},
		search(event) {
			this.filter = event;
			this.getData();
		},
		getData() {
			this.isLoading = true;
			var current_location_id = this.filter.current_location_id;
			if (current_location_id == "all" || current_location_id == null) {
				current_location_id = ""
			}
			if (this.filter.acquisition_date_from != "") {
				this.filter.acquisition_date_from = moment(this.filter.acquisition_date_from).startOf('day') / 1000;
			}
			if (this.filter.acquisition_date_to != "") {
				this.filter.acquisition_date_to = moment(this.filter.acquisition_date_to).startOf('day') / 1000;
				this.filter.acquisition_date_to += 86399;
			}
			axios.get(config.api_path + '/furnishing?asset_no=' + this.filter.asset_no + '&description=' + this.filter.description + '&current_location_id=' + current_location_id + '&type=' + this.filter.type + '&model=' + this.filter.model + '&acquisition_date_from=' + this.filter.acquisition_date_from + '&acquisition_date_to=' + this.filter.acquisition_date_to + '&status=' + this.filter.status + '&page=' + this.currentPage + '&limit=20')
			.then(response => {
				this.dataList = response.data;
				this.dataList.data = this.dataList.data.map((value, index) => {
					value.asset_no = value.asset_no && value.asset_no != "null" ? value.asset_no : "-";
					value.late_transfer_date = value.late_transfer_date ? moment.unix(value.late_transfer_date).format('MMMM DD, YYYY') : "-";
					value.acquisition_date = value.acquisition_date ? moment.unix(value.acquisition_date).format('MM/DD/YYYY') : "-";
					if (value.model == "" || value.model == null) {
						value.model = "-";
					}
					if (value.current_location_id == null) {
						value.current_location_id_label = "-";
					}
					else {
						value.current_location_id_label = value.current_location.customer_name
					}
					value.depot_name = value?.depot?.setting_name ?? '-';
					value.origin_id_label = value?.origin?.customer_name ?? '';
					value.description = value?.description ? this.$options.filters.truncate(value.description) : '-';
					return value;
				});
			})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;
			})
	    },

	    
	    updateModal(item){ 
	    	this.$router.push('/main/update_furnishing/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/main/view_furnishing/'+item.id) 
	    },

	   
		getCustomer(){

				axios.get(config.api_path+'/reference/customer-list', {
					params: {
					show_all : true
				}
					})
				.then(response => {
					this.customerList = response.data; 
			})
		},

		deleteItem(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this furnishing?', 
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete(config.api_path + '/furnishing/' + item.id)
					.then(response => {
						this.getData();
						Swal.fire({
							title: 'Success!',
							text: "Furnishing successfully deleted",
							icon: 'success',
						})
					})
				}
			})
		}
	}
}
</script>
